import React, {useState} from 'react';
import {Alert} from 'react-bootstrap';
import * as apiService from '../api';
import styles from '../CsAgentOld/index.module.scss';
import * as trackAction from '../Application/Shared/TrackActionService';
import cookie from 'react-cookies';
import BulkUploadComponent from
  '../Application/Shared/BulkUpload/BulkUpload';

const BulkUpload = () => {
  const [failedLeadId, setFailedLeadId] =useState(null);
  const [apiResponseCount, setApiResponseCount] = useState(false);
  const [rev3FileData, setRev3FileData] = useState(null);
  const [rev2FileData, setRev2FileData] = useState(null);
  // const columns=[];
  const rev3FileDataHandler = (data) =>{
    setRev3FileData(data);
  };

  const rev2FileDataHandler = (data) =>{
    setRev2FileData(data);
  };

  const rev3UploadAPICall = () => {
    setApiResponseCount(true);
    trackAction.ctaClickAction({
      'cta': 'Start Bulk Upload - REV3',
      'action': 'StartBulkUpload',
      'pageKind': 'CsLoginDashboard',
      'agentName': cookie.load('agent_name'),
    });
    let id=[];
    let apiCount=0;
    if (rev3FileData?.length!==0) {
      for (let i=1; i<rev3FileData.length; i++) {
        const reqObj={
          student_id: rev3FileData[i][0],
          line_items: [
            {
              item_id: rev3FileData[i][1],
              item_type: 'addon',
              eqf_slug: rev3FileData[i][2],
              module_number: rev3FileData[i][3],
              allow_duplicate: true,
            },
          ],
          gateway: {
            name: 'gift',
          },
        };
        id=[...id, rev3FileData[i][0]];
        new apiService.default.CsAgentBulkUpload().uploadExcel(reqObj)
            .then((response)=>{
              // console.log(response);
              apiCount++;
              const index=id.indexOf(response.data.lead_id);
              if (index > -1) {
                id.splice(index, 1);
              }
              setFailedLeadId([...id]);

              // condition to hide loader
              if (apiCount === rev3FileData?.length-1) {
                setApiResponseCount(false);
              }
            })
            .catch((error)=>{
              setFailedLeadId([...id]);
              console.log(error);
              apiCount++;

              // condition to hide loader
              if (apiCount === rev3FileData?.length-1) {
                setApiResponseCount(false);
              }
            });
      }
    }
  };

  return (
    <div className={styles.bulkUploadWrapper}>
      {
        failedLeadId &&
        <Alert
          variant={failedLeadId.length===0?'success':'danger'}
          onClose={() => setFailedLeadId(null)} dismissible
        >
          {
            failedLeadId.length===0?'Bulk Upload Complete':
            <div>
              <p>Upload unsuccessful for following leadIds</p>
              {
                failedLeadId.map((id, index)=>(
                  <p key={index}>{id}</p>
                ))
              }
            </div>
          }
        </Alert>
      }
      <div
        className={styles.rev2Wrapper}
      >
        <p>Rev2</p>
        <BulkUploadComponent
          buttonName={'Start bulk upload - REV2'}
          inputId={'rev2'}
          buttonAction=''
          setFileData={rev2FileDataHandler}
          fileData={rev2FileData}
          isLoading={''}
        />
        {/* {
          rev2RowData && rev2ColumnData &&
              <table className={styles.dataTable}>
                <thead>
                  <tr>
                    {
                      rev2ColumnData.map((col)=>(
                        <th key={col.key}>{col.name}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    rev2RowData.map((row, index) => (
                      <tr key={index}>
                        {
                          row.map((data, innerIndex) => (
                            <td key={innerIndex}>{data}</td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
        }
        {
          isLoading &&
            <Spinner
              animation="grow"
              role="status"
              style={{
                margin: '50px auto',
              }}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
        } */}
      </div>
      <div
        className={styles.rev3Wrapper}
      >
        <p>Rev3</p>
        <BulkUploadComponent
          inputId={'rev3'}
          buttonName={'Start bulk upload - REV3'}
          buttonAction={rev3UploadAPICall}
          setFileData={rev3FileDataHandler}
          fileData={rev3FileData}
          isLoading={apiResponseCount}
        />
        {/* {
          rev3RowData && rev3ColumnData &&
              <table className={styles.dataTable}>
                <thead>
                  <tr>
                    {
                      rev3ColumnData.map((col)=>(
                        <th key={col.key}>{col.name}</th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    rev3RowData.map((row, index) => (
                      <tr key={index}>
                        {
                          row.map((data, innerIndex) => (
                            <td key={innerIndex}>{data}</td>
                          ))
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </table>
        }
        {
          isLoading &&
            <Spinner
              animation="grow"
              role="status"
              style={{
                margin: '50px auto',
              }}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
        } */}
      </div>
    </div>
  );
};

export default BulkUpload;
