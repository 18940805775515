import React, {useState, useEffect} from 'react';
import {
  ColumnFlexWrapper,
  DisplayLayoutContainer,
} from '../Application/Shared2/Agent/styled-components';
import loadingGif from '../../static/loading.gif';
import styles from '../CsAgentOld/index.module.scss';
import {navigate} from 'gatsby';
import * as authService from '../Application/Shared/CsAuthenticationService';

const CsLogin = () => {
  const [authInfo, setAuthInfo] = useState({});
  const [hasMount, triggerMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    if (authInfo && authInfo.isSignedIn.get()) {
      authInfo.signOut();
    } else {
      authInfo?.signIn().then((res)=>{
        if (res) {
          navigate(`/cs-agent/email-search`);
        }
      }).catch(function(error) {
        console.log('login error', error);
      });
    }
  };

  useEffect(() => {
    triggerMount(true);
    authService.triggerAuthentication(setAuthInfo, setIsLoading,
        '/cs-agent/email-search');
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);
  return (
    <>
      {hasMount && <ColumnFlexWrapper>
        <DisplayLayoutContainer width30>
          <p>Login using ShawAcademy Account</p>
          <button
            onClick={handleLogin}
            className={isLoading?styles.disableLoginBtn:styles.loginBtn}
          >
            Login
          </button>
        </DisplayLayoutContainer>
      </ColumnFlexWrapper> }
      {
        isLoading &&
        <img
          src={loadingGif}
          alt='loading'
          className={styles.loadingIcon}
        />
      }
    </>
  );
};

export default CsLogin;
